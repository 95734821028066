import React, { useState } from "react";
import { router, usePage } from '@inertiajs/react'
import SelectInput from "@/Components/Form/SelectInput";

export default function HomeSearch({ }) {
    const { constants } = usePage().props;
    const [values, setValues] = useState({
        region: '',
        game: '',
        platform: '',
        type: 'championships',
    });

    function handleChange(e) {
        const key = e.target.name;
        const value = e.target.value;

        setValues(values => ({
            ...values,
            [key]: value
        }));
    }

    function handleSearch(e) {
        const url = values.type === 'championships' ? route('championships') : route('events');
        const newValues = values;
        delete newValues.type;

        router.visit(
            url,
            {
                data: values
            }
        );
    }

    return (
        <div>
            <div className="overflow-hidden rounded-2xl">
                <img src="/images/bg_home_search.png" alt="Only Racing" className="hidden md:block" />
                <img src="/images/bg_home_search_mobile.jpg" alt="Only Racing" className="md:hidden" />
            </div>

            <div className="flex justify-center">
                <div className="rounded-2xl bg-base-300 p-4 flex flex-col md:flex-row gap-4 flex-wrap -mt-10 w-full md:w-auto mx-4">
                    <SelectInput
                        name="platform"
                        value={values.platform}
                        onChange={handleChange}
                    >
                        <option value="">All platforms</option>
                        {constants.platforms.map((platform) => (
                            <option key={platform.id} value={platform.name}>{platform.name}</option>
                        ))}
                    </SelectInput>

                    <SelectInput
                        name="game"
                        onChange={handleChange}
                    >
                        <option value="">All games</option>
                        {constants.games.map((game) => (
                            <option key={game.id} value={game.name}>{game.name}</option>
                        ))}
                    </SelectInput>

                    <SelectInput
                        name="region"
                        onChange={handleChange}
                    >
                        <option value="">All regions</option>
                        {constants.regions.map((region) => (
                            <option value={region} key={region}>{region}</option>
                        ))}
                    </SelectInput>

                    <SelectInput
                        name="type"
                        onChange={handleChange}
                    >
                        <option value="championships">Championships</option>
                        <option value="events">Events</option>
                    </SelectInput>

                    <button className="btn btn-primary" onClick={handleSearch}>Search</button>
                </div>
            </div>
        </div>
    );
}
